header {
    --gghw: rgb(255 255 255 / 9%);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    z-index: 999;
    backdrop-filter: blur(8px);
    background: linear-gradient(180deg, rgba(255, 255, 255, .6) 0%, rgba(198, 147, 234, .2) 100%);
    -webkit-backdrop-filter: blur(8px);
    mask: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%) add;
    -webkit-mask: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%) add;
    padding: 15px 15px;
    transition: transform 0.3s ease-in-out;
    /* Animation for showing/hiding */

    &.hide {
        transform: translateY(-100%);
        /* Hides the header when scrolling down */
    }

    &.show {
        transform: translateY(0);
        /* Shows the header */
    }


    @media ($breakpoint_MD){
        position: absolute;
    }


    .__container {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0px;

        @media ($breakpoint_MD) {
            justify-content: space-between;
        }

        ._logo {
            width: 60px;
            display: inline-block;
            margin-right: 40px;

            img {
                width: 100%;
                height: 100%;
            }
        }


        .btn-toggle {
            background-color: transparent;
            padding: 9px 20px;
            border-radius: 20px;
            box-shadow: unset !important;
            outline: unset !important;
            border: 1px solid transparent;
            color: var(--txt-white);
            background-color: var(--txt-black);
            transition: all 0.3s linear;
            text-decoration: none;
            font-size: 13px;
            display: none;

            @media ($breakpoint_MD) {
                display: block;
            }

            &:hover {
                color: var(--txt-black);
                border-color: currentColor;
                background-color: transparent;

            }
        }

        .__list {
            width: 100%;

            @media ($breakpoint_MD) {
                background-color: saddlebrown;
                display: none;
            }

            .nav {
                padding: 0;
                margin: 0;
                list-style-type: none;
                align-items: center;
                width: 100%;


                .nav-item {
                    margin: 0;

                    &:hover {
                        background-color: var(--gghw);
                    }


                    &:last-child {
                        background-color: transparent !important;
                        margin-left: auto;
                    }


                    .nav-link {
                        display: inline-block;
                        color: var(--txt-black);
                        padding: 13px 20px;
                        font-size: 14px;
                        font-weight: 500;
                        font-family: $font-Poppins;
                    }

                    .nav-button {
                        display: inline-block;
                        color: var(--txt-white);
                        padding: 9px 25px;
                        border: 1px solid var(--txt-black);
                        background-color: var(--txt-black);
                        border-radius: 30px;
                        font-size: 14px;
                        text-decoration: none;
                        font-family: $font-Poppins;
                        transition: all 0.3s linear;

                        &:hover {
                            background-color: transparent;
                            border-color: currentColor;
                            color: var(--txt-black);
                        }
                    }
                }
            }


        }
    }

}

.sticky-header {
    animation: headerhn 0.5s linear forwards;
    padding-top: 10px;

    .__container {
        background-color: #222;
    }
}


@keyframes headerhn {

    0% {
        position: fixed;
        top: -200px;
    }

    100% {
        position: fixed;
        top: 10px;
    }
}


.--full-header {
    padding: 10px 0;
    background-color: #1e1e20;
    position: sticky;
    top: 0;
    width: 100%;

    .__container {
        background-color: transparent;
    }
}


.offcanvas {
    background: linear-gradient(rgb(239, 230, 249) 0%, rgba(57, 106, 201, 0.3) 100%);
    color: var(--txt-black) !important;
    transition: all 0.2s linear !important;
    max-width: 400px;
    width: 100% !important;
    height: 100% !important;
    font-family: $font-Poppins;
    padding: 0 !important;
    backdrop-filter: blur(10px);
    --border-radius: 10px;
    --backgb: linear-gradient(rgba(110, 63, 159, 0.3) 0%, rgba(178, 98, 185, 0.3) 100%);

    .offcanvas-header {
        background-color: #000;
        padding: 20px;

        .offcanvas-title{
            color: var(--txt-white);
            font-size: 25px;
            font-weight: 300;
            font-family: $font-Poppins;
        }

        .btn-close{
            background-color: var(--txt-white);
            opacity: 1 !important;
            border-radius: 4px !important;
        }
    }

    .offcanvas-body {
        padding: 40px 20px 20px;
        overflow-y: hidden;
        width: 100%;

        @media ($breakpoint_MD) {
            padding: 40px 20px 20px;
        }

        ul {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 20px;
            flex-wrap: nowrap;
            height: 100%;
            gap: 20px;
            padding: 20px 0;
            overflow-y: scroll;
            overflow-x: hidden;
            list-style-type: none;

            li {
                width: 100%;

                a {
                    width: 100%;
                    color: var(--txt-white);
                    background: var(--theme-dark-section);
                    padding: 15px 15px;
                    font-size: 23px;
                    text-align: center;
                    border-radius: 20px;
                    font-weight: 300;
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                        font-size: 15px;
                        font-family: $font-Poppins;
                        font-weight: 300;
                        margin-bottom: 0;

                        span {
                            display: inline-block;
                            margin-right: 10px;

                            svg {
                                display: inline-block;
                                font-size: 2rem;
                                color: var(--txt-white);
                            }
                        }
                    }


                    &:hover {
                        svg {
                            opacity: 1;
                        }
                    }


                    .__extrnal_link {
                        opacity: 0;
                        transition: all 0.2s linear;
                        font-size: 22px;
                    }

                }
            }
        }

        ul::-webkit-scrollbar {
            display: none;
        }

        .__footer-off {
            height: 10%;
            margin-top: 20px;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            h5 {
                margin-bottom: 0;
                opacity: 0.8;
            }
        }
    }
}

.add-class-body {

    header .__container .__list .nav {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
}


// bottomBar scss code 

.__bar {
    background: transparent;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    padding: 10px 10px 20px;
    backdrop-filter: blur(7px);
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%); // Hidden by default

    display: none;

    @media ($breakpoint_MD) {
        display: block;
    }


    &.show {
        transform: translateY(0); // Moves into view
    }

    &.hide {
        transform: translateY(100%); // Moves out of view
    }



    .__center {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 5px;
        background-color: #000;
        backdrop-filter: blur(10px);
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        @media ($breakpoint_MD) {
            width: 75%;
            margin: auto;
        }

        @media ($breakpoint_SM) {
            width: 100%;
        }

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            content: '';
            width: 0;
            height: 0;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            border-bottom: 50px solid #ffffff1f;
            transform: translate(-50%, -50%);
            z-index: -1;
        }


        .__item {
            flex-basis: 20%;

            .__link {
                width: 100%;
                text-decoration: none;
                color: var(--txt-white);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 14px 10px;
                font-family: var(--bs-body-font-family);
                outline: 0 !important;
                border: 0 !important;
                box-shadow: unset !important;
                background-color: transparent;
                border-radius: 20px;
                position: relative;
                z-index: 1;


                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    height: 6px;
                    width: 60%;
                    transform: translate(-50%, 0%);
                    border-radius: 10px 10px 0 0;
                }




                span {
                    margin-bottom: 1px;
                    display: block;
                }


                p {
                    margin-bottom: 0;
                    font-size: 12px;
                    font-weight: 400;
                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
                    text-transform: capitalize;
                }
            }


            &.--active {

                .__link {

                    &::after {
                        background-color: var(--primary-color);

                    }

                }
            }
        }
    }
}