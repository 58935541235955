@import '~bootstrap/scss/bootstrap'; /* including bootstrap 5  */
@import 'react-responsive-modal/styles.css';
@import 'react-toastify/dist/ReactToastify.css';

@import "./common.scss"; /* common scss */
@import "./variable.scss"; /* common scss */


@import "./header.scss";

@import "./footer.scss";

@import "./index.scss";

@import "./login.scss";

@import "./aboutpage.scss";


@import "./website.scss";


@import "./me.scss";


@import "./gradients.scss";


@import "./Homesection.scss";

@import "./blog.scss";

@import "./license.scss";

@import "./GridGenerator.scss";


@import "./demo.scss";