/* src/App.css */
.App {
     text-align: center;
     padding: 20px;
     padding-top: 100px;
   }
   
   input {
     padding: 10px;
     margin: 10px;
     border: 1px solid #ccc;
     border-radius: 4px;
   }
   
   button {
     padding: 10px 20px;
     background-color: #007bff;
     color: white;
     border: none;
     border-radius: 4px;
     cursor: pointer;
   }
   
   button:disabled {
     background-color: #ccc;
     cursor: not-allowed;
   }
   
   .text-area{
      margin-top: 20px;
      border: 1px solid #ccc;
      padding: 10px;
   }

//    .GridGenerator

.color-input-container{
     padding-top: 100px;

}